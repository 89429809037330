<template>
  <div id="vue">   
    <alert :alert="this.$root.alert"></alert>  
    <header></header>     
    <router-view :user="this.$root.user" :loggedIn="this.$root.loggedIn" name="navigation"></router-view>    
    <router-view></router-view>        
  </div>  
</template>
<script>
export default {
  data() {
    return {      
    }
  }
}
</script>