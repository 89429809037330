<template>
    <div class="col">
        <div class="row">
            <div class="col-sm-12 col-md-4" :key="k" v-for="(d, k) in data">
            <contentbox :data="d"></contentbox>
            </div>
        </div>
    </div>
</template>
<script>
import contentbox from '../common/contentbox';
export default {
    components: {
        contentbox: contentbox
    },
    data: function() {
        return {
            data: [
                {
                    icon: 'fas fa-road',
                    color: {background: 'rgb(33, 51, 73)', text: 'rgb(255,255,255)'},
                    header: 'PLAN',
                    body: 'Growth strategies and financial planning support'
                },
                {                    
                    icon: 'fas fa-shield',
                    color: {background: 'rgb(43, 80, 124)', text: 'rgb(255,255,255)'},
                    header: 'PROTECT',
                    body: 'Annuity and Wealth Transfer Solutions'
                },
                {
                    icon: 'fas fa-leaf',
                    color: {background: 'rgb(79, 122, 175)', text: 'rgb(255,255,255)'},
                    header: 'PROVIDE',
                    body: 'Income Planning Strategies'
                },                           
            ]
        }
    }
}
</script>
<style scoped>
.row {
    padding: 30px 30px 50px 30px;
    background-color: transparent;
}
</style>
