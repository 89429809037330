//Base components
import { createApp, h } from 'vue';
import App from './app.vue';

//External modules
import { createWebHistory, createRouter } from "vue-router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

//Internal modules
import alert from './components/common/alert.vue';
import heading from './components/common/heading.vue';
import navigation from './components/common/navigation.vue';
import foot from './components/common/foot.vue';

import about from './components/about/main.vue';
import advisorlink from './components/advisorlink/main.vue';
import careers from './components/careers/main.vue';
import contact from './components/contact/main.vue';
import home from './components/home/main.vue';
import legal from './components/legal/main.vue';
import training from './components/training/main.vue';


//CSS
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/screen.css';
import './assets/css/googlefonts.css';
import './assets/css/floating-labels.css';

//jQuery
window.$ = window.jQuery = require('jquery');

const alertDefault = {
  display: false,
  message: '',
  class: 'alert-primary',
  timeout: false
};

//Routes and route components
const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_SRC),
	base: process.env.VUE_APP_BASE_SRC,	
	routes: [
		{ 
			name: 'home',
			path: '/', 
			components: {
				default: home				
			},
			props: {
				default: true
			}
		},		
		{ 
			name: 'about',
			path: '/about/:anchor?', 
			components: {
				default: about
			},
			props: {
				default: true
			}
		},
		{ 
			name: 'advisorlink',
			path: '/advisorlink', 
			components: {
				default: advisorlink
			},
			props: {
				default: true
			}
		},
		{ 
			name: 'careers',
			path: '/careers', 
			components: {
				default: careers
			},
			props: {
				default: true
			}
		},
		{ 
			name: 'contact',
			path: '/contact', 
			components: {
				default: contact
			},
			props: {
				default: true
			}
		},
		{ 
			name: 'legal',
			path: '/legal-notice', 
			components: {
				default: legal
			},
			props: {
				default: true
			}
		},
		{ 
			name: 'training',
			path: '/training', 
			components: {
				default: training
			},
			props: {
				default: true
			}
		},
		{ 
			name: 'default', 
			path: '/:pathMatch(.*)*',
			redirect: '/home'
		}
	]
});


fetch(process.env.BASE_URL + "config.json")
.then((response) => response.json())
.then((config) => {
	const app = createApp({
		data: function() {
			return {
				user: {},
				alert:  JSON.parse(JSON.stringify(alertDefault)),
				env: process.env.VUE_APP_ENV,           
				loading: true
			}
		},
		methods: {
            logOut: function (message) {                
                this.$cookies.remove(this.$config.app_cookie);
                this.user = null;
                localStorage.setItem('loggedOut', true);
                localStorage.setItem('logoutMessage', JSON.stringify(message));
                this.loggedIn = false;
                this.$router.push({name: 'login'});  
            },
			isLoggedIn: function () {		
                localStorage.removeItem('logoutMessage');		
				const c = this.$config.app_cookie;				
				if (this.$cookies.isKey(c)) {					
					this.user = this.$cookies.get(c); 						
					this.loggedIn = true;					
				} else {	
                    if (this.$route.name != 'login') {				
                        this.$router.push({name: 'login'});           
                    }
				}
			},										
			clearAlert: function() {			
				this.alert = JSON.parse(JSON.stringify(alertDefault));
			},
			setAlert: function(display, message, css) {
				this.alert.display = display;
				this.alert.class = css;
				this.alert.message = message;			
			}
		},
		mounted() {
            //this.isLoggedIn();            
		},
		render: () => h(App)
	});
	//Global modules
	app.use(router);
	app.use(VueCookies);	
	app.use(VueAxios, axios); 	
	//Global components
	app.component('alert', alert);	
	app.component('heading', heading);	
	app.component('navigation', navigation);	
	app.component('foot', foot);    
	//Gobal config
	app.config.globalProperties.$config = config;	
	app.mount('#app');
});
	