<template>
    <div class="modal fade bd-modal-lg" id="modalEmployeeBio" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">                    
                    <h3>{{data.name}}, {{data.title}}</h3>                
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                    
                </div>
                <div class="modal-body">
                    <img 
                        v-if="data.img"
                        class="float-start img-thumbnail mb-2 me-3" 
                        :src="require('@/assets/images/employees/'+data.img)" 
                        :alt="data.name"
                    />
                    <div v-html="data.bio"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['data']
}
</script>
<style scoped>
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    max-height: 400px;
    overflow-y: auto;
}
</style>